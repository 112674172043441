import imagen404 from '../../images/404.png'

const SinPermisos = () => {
  
    return (
      <div className='pos-a t-0 l-0 bgc-white w-100 h-100 d-f fxd-r fxw-w ai-c jc-c pos-r p-30'>
        <div className='mR-60'>
          <img alt="#" src={ imagen404 } />
        </div>
        <div className='d-f jc-c fxd-c'>
          <h1 className='mB-30 fw-900 lh-1 c-red-500' style={{ fontSize: 60 } }>404</h1>
          <h3 className='mB-10 fsz-lg c-grey-900 tt-c'>Vaya, No tienes permisos</h3>
          <p className='mB-30 fsz-def c-grey-700'>No tienes permisos para la página que buscas.</p>
          {/*<div>
            <a href="index.html" type="primary" className='btn btn-primary'>Go to Home</a>
          </div> */}
        </div>
      </div>
    )
  }  
  export default SinPermisos;
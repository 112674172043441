import axios from "axios"
import Swal from 'sweetalert2'
import { getBasePath, getAuth } from '../Utils'

const eliminarRegistro = async (id, tabla) => { 

    await axios.delete(`${getBasePath()}/${tabla}/${id}`, getAuth())
        .then(response =>{
            Swal.fire({
                title: 'Registro eliminado',
                icon: 'success'
            }) 
        })
        .catch(error => {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Error al borrar',
                text: 'Compruebe que el dato a borrar no tiene registros relacionados'
            })
        });
}
export default eliminarRegistro
import React, { useState, useEffect } from 'react'
import useUser from '../../hooks/useUser'
import { getPermisosEnPagina, getBasePath, getAuth } from '../../Utils'
import SinPermisos from '../SinPermisos'
import axios from "axios"
import eliminarRegistro from '../../components/eliminarRegistro'
import { Card, CardBody, Row } from 'reactstrap'
import { ChevronDown, ChevronUp } from 'react-feather'
import './productosDefecto.scss'

const ProductosDefecto = () => {
    const {isLogged, permisosUsuario} = useUser();
    const permisos = getPermisosEnPagina(permisosUsuario, 'Sistema')
    const [hoteles, setHoteles] = useState([])
    const [productos, setProductos] = useState([])
    const [productosDefecto, setProductosDefecto] = useState([])

    const cargarDatos = async () => {
        const {data: hotelesData} = await axios.get(`${getBasePath()}/hoteles`, getAuth());
        const {data: productosData} = await axios.get(`${getBasePath()}/productos`, getAuth());
        const {data: productosDefecto} = await axios.get(`${getBasePath()}/productos_defecto`, getAuth());
        const hotelesPorEmpresa = []
        hotelesData.forEach(el => {
            if (hotelesPorEmpresa[el.empresaId] == undefined) {
                hotelesPorEmpresa[el.empresaId] = []
            }
            hotelesPorEmpresa[el.empresaId].push(el) 
        })
        setHoteles(hotelesPorEmpresa)
        setProductos(productosData.data)
        setProductosDefecto(productosDefecto.data)
    }

    useEffect(() => {
        cargarDatos()
    },[])

    const changeEstadoProducto = async (idProducto, idHotel) => {
        const elementImagen = document.getElementById(`${idProducto}-${idHotel}`)
        const elementCantidad = document.getElementById(`qty-${idProducto}-${idHotel}`)
        const valorCantidad = elementCantidad.value || 0
        const borde = elementImagen.style.border
        if (borde == '') {
            elementImagen.style.border = '3px solid green'
            await axios.post(`${getBasePath()}/productos_defecto`, {hotelId: idHotel, productoId: idProducto, cantidad: valorCantidad}, getAuth());
        } else {
            elementImagen.style.border = '' 
            const prodDefecto = productosDefecto.find(el =>  (el.hotelId == idHotel && el.productoId == idProducto))
            if (prodDefecto != undefined) {
                await axios.delete(`${getBasePath()}/productos_defecto/${prodDefecto.id}`, getAuth())
            }
        }
    }

    const changeEstadoCantidad = async (idProducto, idHotel, {target}) => {
        const prodDefecto = productosDefecto.find(el =>  (el.hotelId == idHotel && el.productoId == idProducto))
        if (prodDefecto != undefined) {
            prodDefecto.cantidad = target.value
            await axios.update(`${getBasePath()}/productos_defecto`, prodDefecto, getAuth());
        }
    }

    const getCantidadProducto = (idProducto, idHotel) => {
        const prodDefecto = productosDefecto.find(el =>  (el.hotelId == idHotel && el.productoId == idProducto))
        if (prodDefecto != undefined) {
            return prodDefecto.cantidad
        }
    }

    const esDefecto = (idProducto, idHotel) =>  {
        if (productosDefecto.length) {
            const pd = productosDefecto.find(el =>  (el.hotelId == idHotel && el.productoId == idProducto))
            return pd == undefined ? '' : '3px solid green'
        }
    }

    const setEstadoHotelView = (idHotel) => {
        const bloque = document.getElementById(`productos-${idHotel}`)
        const estado = bloque.style.display
        bloque.style.display = estado == 'none' ? 'block' : 'none'
    }

    return isLogged && permisos.ver ? (
    <main>
        <h4 className="c-grey-900 mT-10 mB-30">Productos por defecto</h4>
        {hoteles.map(empresa => {
            return (
                <div>
                <h5>{empresa[0].empresaId}</h5>
                { empresa.map(hotel => {
                return (
                    <div>
                        <Card className='mb-2 ml-2'>
                            <div className='d-flex' style={{padding: '10px'}}>
                                <div onClick={() => setEstadoHotelView(hotel.id)}><h6>{hotel.nombre}</h6></div>
                                <div style={{marginLeft: '10px'}} onClick={() => setEstadoHotelView(hotel.id)}><ChevronDown size={15} /></div>
                            </div>
                            <CardBody className='invoice-padding' id={`productos-${hotel.id}`} style={{display: 'none'}}>
                                <div className='contenedor'>
                                    {productos.map(prod => {
                                        return (
                                            <div style={{margin: '10px'}}>
                                                <div id={`${prod.id}-${hotel.id}`} className='cuadrado' style={{border: esDefecto(prod.id, hotel.id)}}>
                                                    <img
                                                        src={`rutaImagen/${prod.imagen}`}
                                                        onClick={() => { changeEstadoProducto(prod.id, hotel.id) }}
                                                        className='cuadrado'
                                                    />
                                                </div>
                                                <div>
                                                    <input 
                                                        type="number" 
                                                        id={`qty-${prod.id}-${hotel.id}`}
                                                        onClick={(target) => changeEstadoCantidad(prod.id, hotel.id, target)}
                                                        defaultValue={getCantidadProducto(prod.id, hotel.id)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    )
                })}
                </div>
            )
        })
    }
    </main>) : (<SinPermisos/>)
}

export default ProductosDefecto
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import './Login.scss'

const Login = ({onLogin}) => { 
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {isLoginLoading, hasLoginError, login, isLogged} = useUser()
  
    useEffect(() => {
      if (isLogged) {
        navigate("/");
        onLogin && onLogin()
      }
    }, [isLogged, onLogin]) 
  
    const handleSubmit = (e) => {
      e.preventDefault();
      login({email: email, password: password})
    };
  
    return (
      <>
        {isLoginLoading && <strong>Comprobando credenciales...</strong>}
        {!isLoginLoading &&
          <section id="loginForm">
            <h2>Iniciar Sesión</h2>
            <form className='form' onSubmit={handleSubmit}>
              <label>Email:</label>
                <input
                id="email"
                placeholder="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
        
              <br/>
              <label>Password: </label>
                <input
                  id="pass"
                  type="password"
                  placeholder="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              <br/>
              <button>Log in</button>
            </form>
          </section>
        }
        {
          hasLoginError && <strong>Email o contraseña incorrecto</strong>
        }
      </>
    );
};

export default Login;